import React from 'react';
import { Spring } from 'react-spring';
import styled from 'styled-components';

import SEO from '../seo';
import MainSection from '../../styles/MainSection';

const DownloadLink = styled.a`
  background: hsl(209, 61%, 16%);
  color: hsl(210, 36%, 96%);
  padding: 15px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  margin-top: 20px;
`;

const Careers = () => (
  <>
    <SEO title="Careers" />
    <MainSection>
      <Spring from={{ opacity: 0, bottom: -40 }} to={{ opacity: 1, bottom: 0 }}>
        {props => (
          <div className="container" style={props}>
            <h1>Careers</h1>
            <p>We're looking for (in no particular order):</p>
            <ol>
              <li>Senior iOS Engineer</li>
              <li>Senior Android Engineer</li>
              <li>Developer Advocate</li>
              <li>Growth Engineer</li>
              <li>Social Media Manger</li>
              <li>Technical Writing</li>
              <li>Marketing</li>
            </ol>
            <p>We're open to full-time, part-time and remote.</p>
            <p>More details coming soon.</p>
            <p>We would love to hear from you!</p>
            <DownloadLink href="mailto:careers@pumabrowser.com?subject=Careers @ Puma">Email us</DownloadLink>
          </div>
        )}
      </Spring>
    </MainSection>
  </>
);

export default Careers;
